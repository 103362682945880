import { EventEmitter } from 'events'

import _ from 'lodash'

import AppCable from './app-cable'

// Requiring the module twice will not allow us to keep state local to this
// file. Likely this is a sprockets commoner thing. So keep the state on global.
global.__channels = global.__channels || {}

// Call `ChatChannel.get(sessionId)` to get one
// They emit events: `chatChannel.on('speak', fn...)`
class ChatChannel {
  static get(sessionId) {
    if (!global.__channels[sessionId]) {
      console.log(`[ChatChannel] Creating new channel for session: ${sessionId}`)
      global.__channels[sessionId] = createChannel(sessionId)
    } else {
      console.log(`[ChatChannel] Using existing channel for session: ${sessionId}`)
    }
    return global.__channels[sessionId]
  }

  constructor(sessionId) {
    this.sessionId = sessionId
    this.emitter = new EventEmitter()
    this.connected = false
    this.connectionStartTime = Date.now()
    console.log(`[ChatChannel] Initializing for session: ${sessionId}`)
    this.connect()
  }

  connect() {
    var sessionId = this.sessionId
    var options = { channel: 'ChatChannel', session: sessionId }
    console.log(`[ChatChannel] Attempting to connect with options:`, options)

    this.server = AppCable.createSubscription(options, {
      connected: () => {
        const connectionTime = Date.now() - this.connectionStartTime
        console.log(`[ChatChannel] Connected to chat in ${connectionTime}ms`)
        this.connected = true
        this.emitter.emit('connect')
      },
      disconnected: () => {
        console.log(`[ChatChannel] Disconnected from chat`)
        this.connected = false
        removeChannel(sessionId)
        this.emitter.emit('disconnect')
      },
      rejected: () => {
        console.error(`[ChatChannel] Rejected from chat - authentication may have failed`)
        removeChannel(sessionId)
        this.emitter.emit('reject')
      },
      received: (data) => {
        console.log(`[ChatChannel] Received data:`, data.type)
        this.emitter.emit(data.type, data)
      },
      getPresence: function() {
        console.log(`[ChatChannel] Getting presence`)
        this.perform('get_presence')
      },
    })
  }

  send(data) {
    console.log(`[ChatChannel] Sending data:`, data.type || data)
    return this.server.send(data)
  }

  clientReady() {
    console.log(`[ChatChannel] Sending clientReady`)
    this.server.send({ type: 'clientReady' })
  }

  getClientReady() {
    console.log(`[ChatChannel] Sending getClientReady`)
    this.server.send({ type: 'getClientReady' })
  }

  getPresence() {
    console.log(`[ChatChannel] Sending getPresence`)
    this.server.getPresence()
  }

  resetClientReady() {
    console.log(`[ChatChannel] Sending resetClientReady`)
    this.server.send({ type: 'resetClientReady' })
  }

  mouseDown(data) {
    this.server.send(_.assign({ type: 'mouseDown' }, data))
  }

  mouseMove(data) {
    this.server.send(_.assign({ type: 'mouseMove' }, data))
  }

  nextExercise(data) {
    this.server.send(_.assign({ type: 'nextExercise' }, data))
  }

  connectionQuality(data) {
    this.server.send(_.assign({ type: 'connectionQuality' }, data))
  }

  bandwidthQuality(data) {
    this.server.send(_.assign({ type: 'bandwidthQuality' }, data))
  }

  jumpToWrapup(data) {
    this.server.send(_.assign({ type: 'jumpToWrapup' }, data))
  }

  endSession() {
    console.log(`[ChatChannel] Sending endSession`)
    this.server.send({ type: 'endSession' })
  }

  log(message) {
    this.server.send({ type: 'log', message: message })
  }

  on(event, listener) {
    if (event === 'connect' && this.connected) {
      console.log(`[ChatChannel] Already connected, triggering connect listener immediately`)
      listener()
    }
    this.emitter.on(event, listener)
  }

  off(event, listener) {
    this.emitter.removeListener(event, listener)
  }
}

function removeChannel(sessionId) {
  console.log(`[ChatChannel] Removing channel for session: ${sessionId}`)
  delete global.__channels[sessionId]
}

function createChannel(sessionId) {
  return new ChatChannel(sessionId)
}

export default ChatChannel
