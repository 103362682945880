import { createConsumer } from '@rails/actioncable'

global.__cable = null

class AppCable {
  static createSubscription(options, definition) {
    if (!global.__cable) {
      console.log('[AppCable] Creating new ActionCable consumer')

      // Get the ActionCable URL from the meta tag, if available
      const actionCableUrl = document.querySelector('meta[name="action-cable-url"]')?.content;

      if (actionCableUrl) {
        console.log(`[AppCable] Using ActionCable URL from meta tag: ${actionCableUrl}`);
        global.__cable = createConsumer(actionCableUrl);
      } else {
        console.log('[AppCable] No ActionCable URL found in meta tag, using default');
        global.__cable = createConsumer();
      }

      // Add event listeners for connection monitoring
      global.__cable.connection.onopen = () => {
        console.log('[AppCable] WebSocket connection OPENED');
      };

      global.__cable.connection.onclose = (event) => {
        console.log(`[AppCable] WebSocket connection CLOSED. Clean: ${event.wasClean}, Code: ${event.code}`);
      };

      global.__cable.connection.onerror = () => {
        console.error('[AppCable] WebSocket connection ERROR');
      };
    }

    console.log(`[AppCable] Creating subscription with options:`, options);
    return global.__cable.subscriptions.create(options, definition);
  }
}

export default AppCable
