import _ from 'lodash'

import Module from 'lib/module'
import ChatChannel from 'lib/actioncable/chat-channel'
import { t } from 'src/lib/i18n'

export default class ChatReadyHandshake extends Module {
  constructor(element, options) {
    super(element, options)
    this.element = element
    this.options = _.defaults({}, options, {
      enabled: null,
      onChangeState: () => {},
    })

    this.$el = $(element)
    this.button = this.$el.find('.chat-ready-handshake__start')
    this.sessionId = this.$el.attr('data-session-id')
    this.userRole = this.$el.attr('data-user-role')
    this.otherRole = this.userRole === 'student' ? 'teacher' : 'student'
    this.state = null
    this.connectionAttempts = 0

    if (this.sessionId) {
      console.log(`[ActionCable] Attempting to connect for session: ${this.sessionId}`)
      this.connectChannelWithRetries(2)
    } else {
      console.error('[ActionCable] No session id for ChatReadyHandshake!')
    }
    this.button.click(this.handleStartButtonClick)
    if (this.options.enabled != null) this.setEnabled(this.options.enabled)
  }

  handleConnectionTimeout(retries) {
    this.connectionAttempts++
    console.log(`[ActionCable] Connection attempt ${this.connectionAttempts} timed out after 5000ms`)

    if (retries > 0) {
      console.log(
        `[ActionCable] Will retry connection. ${retries} retries remaining.`
      )
      this.connectChannelWithRetries(retries - 1)
    } else {
      console.error('[ActionCable] CONNECTION FAILED: All retries exhausted')

      // Try to diagnose the issue
      const cookies = document.cookie
      console.log('[ActionCable] Document cookies present:', cookies.length > 0)

      // Check if we are on HTTPS as cookies might be blocked on non-secure connections
      console.log('[ActionCable] Protocol:', window.location.protocol)

      // Log if third-party cookies might be blocked
      if (window.navigator && window.navigator.userAgent) {
        console.log('[ActionCable] Browser:', window.navigator.userAgent)
      }

      // Report to Sentry
      window.Sentry.withScope((scope) => {
        scope.setExtra({ session_id: this.sessionId })
        scope.setExtra({ connection_attempts: this.connectionAttempts })
        scope.setExtra({ user_role: this.userRole })
        scope.setExtra({ has_cookies: cookies.length > 0 })
        scope.setExtra({ protocol: window.location.protocol })
        window.Sentry.captureException(
          new Error('ActionCable connection retries exhausted')
        )
      })
      this.setState('error')
    }
  }

  handleConnected() {
    clearTimeout(this.connectionTimeout)
    console.log(`[ActionCable] Successfully connected after ${this.connectionAttempts + 1} attempts`)
    this.chatChannel.getClientReady()
  }

  connectChannelWithRetries(retries) {
    console.log(`[ActionCable] Attempting to connect (attempt ${this.connectionAttempts + 1})`)
    this.chatChannel = ChatChannel.get(this.sessionId)

    this.chatChannel.on('connect', this.handleConnected)
    this.chatChannel.on('clientReady', this.handleClientReady)
    this.chatChannel.on('getClientReady', this.handleClientReady)
    this.chatChannel.on('resetClientReady', this.handleClientReady)

    // Also listen for reject events to better diagnose issues
    this.chatChannel.on('reject', () => {
      console.error('[ActionCable] Connection was rejected by the server')
      clearTimeout(this.connectionTimeout)
      this.setState('error')
    })

    // Also listen for disconnect events
    this.chatChannel.on('disconnect', () => {
      console.log('[ActionCable] Connection was disconnected')
    })

    console.log('[ActionCable] Setting connection timeout of 5000ms')
    this.connectionTimeout = window.setTimeout(
      () => this.handleConnectionTimeout(retries),
      5000
    )
  }

  handleClientReady(data) {
    data = data || {}
    console.log(`[ActionCable] Client ready event received:`, data)
    if (data.both) this.setState('both-ready')
    else if (data[this.userRole]) this.setState('me-ready')
    else if (data[this.otherRole]) this.setState('other-ready')
    else this.setState('none-ready')
  }

  handleStartButtonClick() {
    console.log('[ActionCable] Start button clicked, sending clientReady')
    this.chatChannel.clientReady()
  }

  getState() {
    return this.state
  }

  setState(state) {
    console.log(`[ActionCable] State changed to: ${state}`)
    this.state = state
    this.$el.removeClass(
      this.states
        .map(function(s) {
          return 'chat-ready-handshake_' + s
        })
        .join(' ')
    )
    this.$el.addClass('chat-ready-handshake_' + state)
    this.options.onChangeState(this.state)
  }

  setEnabled(enabled) {
    this.button.prop('disabled', !enabled)
    this.button.attr(
      'title',
      enabled
        ? ''
        : t('Please check your audio / video settings before continuing!')
    )
  }
}

ChatReadyHandshake.prototype.states = [
  'error',
  'none-ready',
  'other-ready',
  'me-ready',
  'both-ready',
]
ChatReadyHandshake.className = 'chat-ready-handshake'
ChatReadyHandshake.selector = `.${ChatReadyHandshake.className}`
